import { useContext, useState, useEffect } from "react";
import AuthContext from "../context/auth/AuthContext";
import { healthCheck, getCustomers, getCustomer, loginService, refreshTokenExchange } from "../services/apiService";

export const useFetchCustomers = (customerId) => {
    const { accessToken } = useContext(AuthContext);
    const [customers, setCustomers] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [lastCustomerId, setLastCustomerId] = useState(-1);

    useEffect(() => {
        if (!accessToken) {
            return;
        }

        if (customerId !== lastCustomerId) {
            console.log('Fetching customers...');
            setLastCustomerId(customerId);
            setLoading(true);
            setError(null);
            if (customerId) {
                getCustomer(accessToken, customerId)
                .then((data) => setCustomers(data.customers))
                .catch((e) => setError(e))
                .finally(() => setLoading(false));
            } else {
                getCustomers(accessToken)
                .then((data) => setCustomers(data.customers))
                .catch((e) => setError(e))
                .finally(() => setLoading(false));
            }
        }
    }, [accessToken, customers, customerId, lastCustomerId]);

    return { customers, loading, error };
}

export const useFetchHealthCheck = (trigger) => {
    const [healthCheckData, setHealthCheckData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [triggerState, setTriggerState] = useState(!trigger);

    useEffect(() => {
        if (triggerState !== trigger) {
            setTriggerState(trigger);
            healthCheck()
            .then((healthCheckData) => setHealthCheckData(healthCheckData))
            .catch((e) => setError(e))
            .finally(() => setLoading(false));
        }

    }, [healthCheckData, trigger, triggerState]);

    return { healthCheckData, loading, error };
}

export const useLogin = () => {
    const { setAccessToken, setRefreshToken } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const login = async(username, password) => {
        setLoading(true);
        setError(null);
        loginService(username, password)
        .then((data) => {
            setAccessToken(data.access_token);
            setRefreshToken(data.refresh_token);
            localStorage.setItem('refreshToken', data.refresh_token);
        })
        .catch((e) => setError(e))
        .finally(() => setLoading(false));
    }

    return { login, loading, error };
}

export const useRefreshToken = () => {
    const { setAccessToken, setRefreshToken } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const exchangeRefreshToken = async(refreshToken) => {
        setLoading(true);
        setError(null);
        const data = await refreshTokenExchange(refreshToken);
        if (!data || !data.access_token || !data.refresh_token) {
            setError('No data returned from refresh token exchange');
            setLoading(false);
            return;
        }

        setAccessToken(data.access_token);
        setRefreshToken(data.refresh_token);
        localStorage.setItem('refreshToken', data.refresh_token);
        setLoading(false);
    }

    return { exchangeRefreshToken, loading, error };
}