const BASE_URL = '/api';

const URLS = {
    HEALTH: `${BASE_URL}/health`, // GET ~ no auth
    LOGIN: `${BASE_URL}/user/login`, // POST { username, password } ~ no auth
    CUSTOMERS: `${BASE_URL}/customer`, // GET ~ with auth
    CUSTOMER: `${BASE_URL}/customer`, //  + / + customerId ~ with auth
    REFRESH_TOKEN: `${BASE_URL}/user/token/refresh`, // POST { refresh_token } ~ no auth
}

const fetchWithToken = async(url, token) => {
    const response = await fetch(url, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }
    // TODO: Should we wait for the response to be JSON before returning
    return response.json();
}

export const healthCheck = async() => {
    const response = await fetch(URLS.HEALTH);
    return response.json();
}

export const loginService = async(username, password) => {
    console.log('loginService', username, password);
    const response = await fetch(URLS.LOGIN, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username, password })
    });
    return response.json();
}

export const getCustomers = async(token) => {
    return fetchWithToken(URLS.CUSTOMERS, token);
}

export const getCustomer = async(token, customerId) => {
    const url = `${URLS.CUSTOMER}/${customerId}`;
    return fetchWithToken(url, token);
}

export const refreshTokenExchange = async(refreshToken) => {
    const response = await fetch(URLS.REFRESH_TOKEN, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ refresh_token: refreshToken })
    });
    return response.json();
}