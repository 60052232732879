import React, { useState } from 'react';
import AuthContext from './AuthContext';

function AuthProvider({ children }) {
    const [accessToken, setAccessToken] = useState(null);
    const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refreshToken'));
    const [user, setUser] = useState(null);

    // The value that will be provided to consuming components
    const value = { accessToken, setAccessToken, refreshToken, setRefreshToken, user, setUser  };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;