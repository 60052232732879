import { useContext, useEffect } from 'react';
import AuthContext from '../context/auth/AuthContext';
import { refreshTokenExchange } from '../services/apiService';

export function useProvideAuth() {
    useEffect(() => {
        isAuthencated(true);
    });

    const { accessToken, setAccessToken, refreshToken, setRefreshToken } = useContext(AuthContext);


    function isAuthencated(shouldRefresh = false) {
        console.log('isAuthencated?');
        if (accessToken) {
            return true;
        }

        if (!refreshToken) {
            return false;
        }

        if (shouldRefresh) {

            console.log('Refreshing token...');
            refreshTokenExchange(refreshToken).then((data) => {
                console.log('Refresh token exchange data: ', data);
                const accessToken = data.access_token;
                const refreshToken = data.refresh_token;

                setAccessToken(accessToken);
                setRefreshToken(refreshToken);
                localStorage.setItem('refreshToken', refreshToken);

                console.log('Access token is now: ', accessToken);
                const authed = accessToken !== null;
                console.log('isAuthencated? ', authed);
                return authed;
            });

        }
        return false;
    }

    return {
        isAuthencated,
    };
}
