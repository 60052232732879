import './App.css';
import HealthCheck from './components/HealthCheck/HealthCheck';
import Logo from './components/Logo/Logo';
import Login from './components/Login/Login';
import Customers from './components/Customers/Customers';
import Header from './components/Header/Header';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { RequireAuth } from './components/RequireAuth/RequireAuth';

function App() {
  return (
    <div className="App">
      <Router>
        <Header></Header>
        <Logo></Logo>
        <Routes>
          <Route path="/" element={<HealthCheck />} />
          <Route path="/customers" element={<RequireAuth><Customers /></RequireAuth>} />
          <Route path="/login" element={ <RequireAuth><Login /></RequireAuth> } />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
