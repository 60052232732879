import React, { useState, useContext } from 'react';
import AuthContext from '../../context/auth/AuthContext';
import { useLogin } from '../../hooks/useFetchFromApi';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { accessToken } = useContext(AuthContext);
    const { login, loading, error } = useLogin();

    const handleSubmit = event => {
        event.preventDefault();
        login(username, password);
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error.message}</p>;
    }

    if (accessToken) {
        console.log('Login - Already logged in.');
        return (
            <div>
                Already logged in.
            </div>
        );
    }

    return (
        <form onSubmit={handleSubmit}>
            <label>
                Username:
                <input type="text" value={username} onChange={e => setUsername(e.target.value)} />
            </label>
            <label>
                Password:
                <input type="password" value={password} onChange={e => setPassword(e.target.value)} />
            </label>
            <input type="submit" value="Log in" />
        </form>
    );
}

export default Login;