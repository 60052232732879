import { Navigate, useLocation } from 'react-router-dom';
import { useProvideAuth } from '../../hooks/useAuth';

function RequireAuth({ children }) {
    const { isAuthencated } = useProvideAuth();
    const location = useLocation();
    const path = location.pathname;


    const authed = isAuthencated(true);
    console.log('RequireAuth: ', authed);
    if (!authed && path !== '/login') {
        console.log('RequireAuth - NOT AUTHED');
        return <Navigate to="/login" state={{ from: location }} />;
    }

    if (authed && path === '/login') {
        return <Navigate to="/" state={{ from: location }} />;
    }

    return children;
}

export { RequireAuth };
