import { Link } from "react-router-dom";
import { useProvideAuth } from "../../hooks/useAuth";

function Header() {
    const { isAuthencated } = useProvideAuth();

    const authed = isAuthencated();
    return (
        <div>
        <h1>Our App using React and a Cloudflare Worker for the API</h1>
            <ul>
                <li><Link to="/">Home</Link></li>
                {
                    authed
                    ? <li><Link to="/customers">Customers</Link></li>
                    : null
                }

                {
                    authed
                    ? <li><Link to="/logout">Logout</Link></li>
                    : <li><Link to="/login">Login</Link></li>
                }

            </ul>
        </div>
    );
}

export default Header;
