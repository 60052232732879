import React, { useState } from 'react';
import { useFetchCustomers } from '../../hooks/useFetchFromApi';

function Customers() {
    const [customerId, setCustomerId] = useState('');
    const { customers, loading, error } = useFetchCustomers(customerId);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error.message}</p>;
    }

    const handleInputChange = (e) => {
        setCustomerId(e.target.value);
    }
    return (
        <div>
            <p>Customers:</p>
                <input type="text" value={customerId} onChange={handleInputChange} />

            <ul>
                {customers.map((customer) => (
                    <li key={customer.CustomerId}>{customer.CompanyName} ~ {customer.ContactName}</li>
                ))}
            </ul>
        </div>
    );
}
export default Customers;