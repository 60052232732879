const divStyle = {
    maxWidth: '4rem',
};

const imgStyle = {
    width: '100%',
    height: 'auto'
};


function Logo(){
    return (
        <div className="logo" style={ divStyle }>
        <img style={ imgStyle } src="DoD-Worker.png" alt="DevOpsDave Worker logo" />
        </div>
    );
}

export default Logo;