import { useState } from "react";

import { useFetchHealthCheck } from '../../hooks/useFetchFromApi';

function HealthCheck() {
    const [trigger, setTrigger] = useState(false);
    const { healthCheckData, loading, error } = useFetchHealthCheck(trigger);


    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error.message}</p>;
    }

    return (
        <div>
            <code>{JSON.stringify(healthCheckData)}</code>
            <button onClick={() => setTrigger(!trigger)}>Trigger</button>
        </div>
    );
}

export default HealthCheck;